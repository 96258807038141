import axios, { AxiosInstance } from 'axios';
import { GameState, GameInfo, Card, CardPack, Message, Vote } from '../types/game';

// Add these new interfaces for admin functionality
interface AdminDocument {
    _id: string;
    [key: string]: any;
}

interface PaginatedResponse {
    docs: AdminDocument[];
    total: number;
    pages: number;
    currentPage: number;
}

const API_URL = `${process.env.REACT_APP_API_URL}/api/game`;
const IMAGE_URL = `${process.env.REACT_APP_API_URL}/api/images`;
const AUTH_URL = `${process.env.REACT_APP_API_URL}/api/auth`;
const ADMIN_URL = `${process.env.REACT_APP_API_URL}/api/admin`;

// Create axios instance with authentication interceptor
const axiosInstance = axios.create();

// Add authentication token to all requests
axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');

    if (token) {
        // Remove any 'auth_' or 'temp_' prefixes from the token
        const cleanToken = token.replace(/^(auth_|temp_)/, '');

        config.headers['Authorization'] = `Bearer ${cleanToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Add response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status === 401) {
            // Clear local storage
            localStorage.removeItem('token');
            localStorage.removeItem('username');

            // Redirect to login page
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

class Api {
    private instance: AxiosInstance;
    private adminInstance: AxiosInstance;

    constructor() {
        // Create main API instance
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        });

        // Create admin-specific instance
        this.adminInstance = axios.create({
            baseURL: `${process.env.REACT_APP_API_URL}/api/admin`
        });

        // Add auth interceptor for admin routes
        this.adminInstance.interceptors.request.use((config) => {
            const adminToken = localStorage.getItem('adminToken');
            if (adminToken) {
                config.headers.Authorization = `Bearer ${adminToken}`;
            }
            return config;
        });

        // Add error interceptor for admin routes
        this.adminInstance.interceptors.response.use(
            response => response,
            error => {
                if (error.response?.status === 401) {
                    localStorage.removeItem('adminToken');
                    window.location.href = '/admin/login';
                }
                return Promise.reject(error);
            }
        );
    }

    // Game management endpoints
    createGame = async (
        playerName: string,
        gameName: string,
        winningScore: number,
        selectedBlackPacks: CardPack[],
        selectedWhitePacks: CardPack[],
        botCount: number
    ): Promise<{ gameId: string, creatorId: string, playerId: string }> => {
        const response = await this.instance.post(`${API_URL}/create`, {
            playerName,
            gameName,
            winningScore,
            blackCardPacks: selectedBlackPacks,
            whiteCardPacks: selectedWhitePacks,
            botCount
        });
        return response.data;
    };

    joinGame = async (gameId: string, playerName: string): Promise<{ playerId: string, gameState: GameState }> => {
        const response = await this.instance.post(`${API_URL}/${gameId}/join`, { playerName });
        return response.data;
    };

    leaveGame = async (gameId: string, playerId: string): Promise<any> => {
        const response = await this.instance.post(`${API_URL}/${gameId}/leave`, { playerId });
        return response.data;
    };

    deleteGame = async (gameId: string, creatorId: string): Promise<any> => {
        const response = await this.instance.delete(`${API_URL}/${gameId}/delete`, { data: { creatorId } });
        return response.data;
    };

    getGameState = async (gameId: string, playerId: string): Promise<GameState> => {
        const response = await this.instance.get(`${API_URL}/${gameId}`, {
            params: { playerId }
        });
        return response.data;
    };

    rejoinGame = async (gameId: string, playerId: string, playerName: string): Promise<void> => {
        await this.instance.post(`${API_URL}/${gameId}/rejoin`, { playerId, playerName });
    };

    startGame = async (gameId: string, creatorId: string): Promise<void> => {
        await this.instance.post(`${API_URL}/${gameId}/start`, { creatorId });
    };

    restartGame = async (gameId: string, creatorId: string): Promise<GameState> => {
        const response = await this.instance.post(`${API_URL}/${gameId}/restart`, { creatorId });
        return response.data;
    };

    playCard = async (gameId: string, playerId: string, cardIds: string[]): Promise<GameState> => {
        console.log(`Attempting to play cards: gameId=${gameId}, playerId=${playerId}, cardIds=${cardIds}`);
        const response = await this.instance.post(`${API_URL}/${gameId}/play-card`, { playerId, cardIds });
        return response.data;
    };

    selectWinner = async (gameId: string, winningPlayerId: string): Promise<GameState> => {
        const response = await this.instance.post(`${API_URL}/${gameId}/select-winner`, { winningPlayerId });
        return response.data;
    };

    revealCard = async (gameId: string, playerId: string): Promise<{ game: GameState }> => {
        const response = await this.instance.post(`${API_URL}/${gameId}/reveal-card`, { playerId });
        return response.data;
    };

    getCurrentGames = async (): Promise<GameInfo[]> => {
        const response = await this.instance.get(`${API_URL}/current-players`);
        console.log("response", response.data);
        return response.data.games;
    };

    getAvailablePacks = async () => {
        const response = await this.instance.get(`${API_URL}/available-packs`);
        return response.data.availablePacks;
    };

    updateOnlineStatus = async (gameId: string, playerId: string, isOnline: boolean): Promise<void> => {
        await this.instance.post(`${API_URL}/${gameId}/online-status`, { playerId, isOnline });
    };

    getOnlineUsers = async (gameId: string): Promise<{ id: string, name: string }[]> => {
        const response = await this.instance.get(`${API_URL}/${gameId}/online-users`);
        return response.data.onlineUsers;
    };

    sendChatMessage = async (gameId: string, message: Message): Promise<void> => {
        await this.instance.post(`${API_URL}/${gameId}/chat`, message);
    };

    getOlderMessages = async (gameId: string, page: number): Promise<{ messages: Message[], hasMore: boolean }> => {
        const response = await this.instance.get(`${API_URL}/${gameId}/older-messages`, { params: { page } });
        return response.data;
    };

    // Card pack management endpoints
    createPack = async (name: string, isPublic: boolean, imageUrl?: string): Promise<CardPack> => {
        try {
            const response = await this.instance.post(`${API_URL}/packs`, {
                name,
                isPublic,
                imageUrl
            });
            console.log('CreatePack API - Response:', response.data);
            return response.data;
        } catch (error) {
            console.error('CreatePack API - Error:', error);
            throw error;
        }
    };

    deletePack = async (packId: string): Promise<void> => {
        await this.instance.delete(`${API_URL}/packs/${packId}`);
    };

    createCard = async (packId: string, text: string, type: 'black' | 'white', blanks?: number): Promise<void> => {
        try {
            await this.instance.post(`${API_URL}/cards`, {
                pack: packId,
                text,
                type,
                blanks
            });
        } catch (error) {
            console.error('Error creating card:', error);
            throw error;
        }
    };

    editCard = async (cardId: string, text: string, type: 'black' | 'white', blanks?: number): Promise<void> => {
        const payload = { text, type };
        if (type === 'black' && blanks !== undefined) {
            Object.assign(payload, { blanks });
        }
        await this.instance.put(`${API_URL}/cards/${cardId}`, payload);
    };

    deleteCard = async (cardId: string, userId: string): Promise<void> => {
        if (!cardId) {
            throw new Error('Card ID is undefined');
        }
        await this.instance.delete(`${API_URL}/cards/${cardId}`, { data: { userId } });
    };

    getPackCards = async (packId: string): Promise<Card[]> => {
        const response = await this.instance.get(`${API_URL}/packs/${packId}/cards`);
        return response.data;
    };

    rateCardPack = async (packId: string, rating: number, cardType: 'black' | 'white' | 'both') => {
        const response = await this.instance.post(`${API_URL}/packs/${packId}/rate`, {
            rating,
            cardType
        });
        return response.data;
    };

    // Authentication endpoints
    signup = async (username: string, email: string, password: string): Promise<{ token: string }> => {
        const response = await this.instance.post(`${AUTH_URL}/signup`, { username, email, password });
        return response.data;
    };

    login = async (username: string, password: string): Promise<{ token: string }> => {
        const response = await this.instance.post(`${AUTH_URL}/login`, { username, password });
        return response.data;
    };

    joinWithoutSignup = async (username: string): Promise<{ token: string }> => {
        const response = await this.instance.post(`${AUTH_URL}/join-without-signup`, { username });
        return response.data;
    };

    uploadDeckImage = async (formData: FormData) => {
        console.log('Starting image upload...');
        console.log('API URL:', `${IMAGE_URL}/upload`);
        console.log('FormData contents:', Array.from(formData.entries()));

        try {
            const response = await this.instance.post(`${IMAGE_URL}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Upload successful:', response.data);
            return response.data;
        } catch (error: any) {
            console.error('Upload failed:', error);
            console.error('Error response:', error.response?.data);
            console.error('Error status:', error.response?.status);
            throw error;
        }
    };
    
    

    // Admin methods
    admin = {
        login: async (username: string, password: string): Promise<string> => {
            const response = await this.instance.post('/api/admin/auth/login', { username, password });
            return response.data.token;
        },

        getCollections: async (): Promise<string[]> => {
            const response = await this.adminInstance.get('/collections');
            return response.data;
        },

        getDocuments: async (
            collection: string,
            page: number = 1,
            limit: number = 10
        ): Promise<PaginatedResponse> => {
            const response = await this.adminInstance.get(`/collections/${collection}`, {
                params: { page, limit }
            });
            return response.data;
        },

        updateDocument: async (
            collection: string,
            id: string,
            data: any
        ): Promise<AdminDocument> => {
            const response = await this.adminInstance.put(
                `/collections/${collection}/${id}`,
                data
            );
            return response.data;
        },

        deleteDocument: async (collection: string, id: string): Promise<void> => {
            await this.adminInstance.delete(`/collections/${collection}/${id}`);
        },

        getStats: async (): Promise<{
            games: number;
            cards: number;
            cardPacks: number;
            users: number;
        }> => {
            const response = await this.adminInstance.get('/stats');
            return response.data;
        }
    };

    // Game-specific methods
    getMessagesSince = async (gameId: string, timestamp: Date): Promise<{ messages: Message[] }> => {
        try {
            const response = await this.instance.get(
                `${API_URL}/${gameId}/messages-since/${timestamp.toISOString()}`
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching messages since timestamp:', error);
            throw error;
        }
    };

    initiateVote = async (gameId: string, playerId: string, cardCount: number): Promise<Vote> => {
        const response = await this.instance.post(`${API_URL}/${gameId}/initiate-vote`, { playerId, cardCount });
        return response.data.vote;
    };

    submitVote = async (gameId: string, playerId: string, vote: boolean): Promise<void> => {
        await this.instance.post(`${API_URL}/${gameId}/submit-vote`, { playerId, vote });
    };

    selectCardsToChange = async (gameId: string, playerId: string, cardIds: string[]): Promise<void> => {
        await this.instance.post(`${API_URL}/${gameId}/select-cards`, { playerId, cardIds });
    };
}

export const api = new Api();
