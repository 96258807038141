import { io } from 'socket.io-client';
import { Vote } from '../types/game';

const SOCKET_URL = `${process.env.REACT_APP_API_URL}`;

console.log(`Initializing socket connection to: ${SOCKET_URL}`);

const socket = io(SOCKET_URL, {
    transports: ['websocket', 'polling'],
    autoConnect: true,
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 20000,
});

socket.on('connect', () => {
    console.log('Socket connected with ID:', socket.id);
});

socket.on('connect_error', (error) => {
    console.error('Socket connection error:', error);
});

socket.on('disconnect', (reason) => {
    console.log('Socket disconnected:', reason);
});

socket.on('error', (error) => {
    console.error('Socket error:', error);
});

socket.on('voteStarted', (vote) => {
    console.log('Vote started event received in socket.ts:', vote);
});

socket.on('voteUpdated', (vote) => {
    console.log('Vote updated event received in socket.ts:', vote);
});

socket.on('voteResolved', (data: { passed: boolean, vote: Vote }) => {
    console.log('Vote resolved:', data);
});

socket.on('cardSelectionUpdated', (data: { 
    playerId: string, 
    selectedCount: number, 
    requiredCount: number 
}) => {
    console.log('Card selection updated:', data);
});

socket.on('cardsChanged', (data: { message: string }) => {
    console.log('Cards changed:', data);
});

export default socket;
