import { motion } from 'framer-motion'
import { Crown, Check, Lock } from 'lucide-react'
import styles from '../styles/Cards.module.css'

interface CardOverlayProps {
    type: 'played' | 'card-czar'
    className?: string
}

export default function CardOverlay({ type, className = '' }: CardOverlayProps) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={`${styles.overlay} ${className}`}
        >
            <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                className={type === 'played' ? styles.playedIcon : styles.czarIcon}
            >
                {type === 'played' ? (
                    <div className={styles.overlayIcon}>
                        <Check className={styles.icon} strokeWidth={3} />
                    </div>
                ) : (
                    <div className={styles.overlayIcon}>
                        <Crown className={styles.icon} strokeWidth={3} />
                    </div>
                )}
            </motion.div>
            <motion.div
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 1 }}
                className={`${styles.gradient} ${
                    type === 'played' ? styles.playedGradient : styles.czarGradient
                }`}
            />
            <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className={styles.lockIcon}
            >
                <Lock className={`${styles.icon} ${type === 'played' ? styles.playedLock : styles.czarLock}`} />
            </motion.div>
        </motion.div>
    )
} 