import React from 'react';
import { Send } from 'lucide-react';
import styles from '../../styles/chat.module.css';

interface ChatInputProps {
    inputMessage: string;
    setInputMessage: (message: string) => void;
    onSubmit: (e: React.FormEvent) => void;
    onFocus: () => void;
}

export default function ChatInput({
    inputMessage,
    setInputMessage,
    onSubmit,
    onFocus
}: ChatInputProps) {
    return (
        <form onSubmit={onSubmit} className={styles.inputForm}>
            <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onFocus={onFocus}
                placeholder="Type a message..."
                className={styles.input}
            />
            <button type="submit" className={styles.sendButton}>
                <Send className={styles.sendIcon} />
            </button>
        </form>
    );
}