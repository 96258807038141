const colors = [
    '#1E88E5',  // bright blue
    '#D81B60',  // bright pink 
    '#43A047',  // bright green
    '#F4511E',  // bright orange
    '#8E24AA',  // bright purple
    '#00ACC1',  // bright cyan
    '#E53935',  // bright red
    '#3949AB',  // bright indigo
];

export const getPlayerColor = (username: string): string => {
    // Generate a consistent index based on username
    const index = username.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return colors[index % colors.length];
};