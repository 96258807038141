import React from 'react';
import styles from '../../styles/chat.module.css';
import { ChatMessage as ChatMessageType } from '../../types/chat';
import { getPlayerColor } from './colorUtils';

interface ChatMessageProps {
  message: ChatMessageType;

}

export default function ChatMessage({ message }: ChatMessageProps) {
  const messageStyle = message.username ? {
    color: getPlayerColor(message.username)
  } : undefined;

  return (
    <div className={styles.messageWrapper}>
      {message.sender !== 'system' && message.username && (
        <span
          className={styles.username}
          style={messageStyle}
        >
          {message.username}:
        </span>
      )}
      <span
        className={`${styles.message} ${styles[message.sender]}`}
        style={message.sender !== 'system' ? messageStyle : undefined}
      >
        {message.content}
      </span>
    </div>
  );
}