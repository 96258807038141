// OtherGames.tsx

import { useEffect, useState } from 'react'
import styles from '../../styles/OtherGames.module.css';
import {  GameInfo } from '../../types/game';
import { GhostIcon, Users, Trash, X, Check } from "lucide-react"

interface OtherGamesProps {
    games: GameInfo[];
    onJoinGame: (gameId: string) => void;
    username: string;
    onDeleteGame: (gameId: string) => void;
    myGames: boolean;
}

function OtherGames({ games, onJoinGame, username, onDeleteGame, myGames }: OtherGamesProps) {
    console.log("games", games);
    const [searchName, setSearchName] = useState('');
    const [deletingGameId, setDeletingGameId] = useState<string | null>(null);

    const filteredGames = games.filter(game =>
        game.gameName.toLowerCase().includes(searchName.toLowerCase())
    );
    console.log("filteredGames", filteredGames);
    

    useEffect(() => {
        console.log("games:", games);
    }, [games]);

   

    const handleDeleteClick = (gameId: string) => {
        if (deletingGameId === gameId) {
            onDeleteGame(gameId);
            setDeletingGameId(null);
        } else {
            setDeletingGameId(gameId);
        }
    };

    const handleCancelDelete = () => {
        setDeletingGameId(null);
    };

    // Reset delete state when games change
    useEffect(() => {
        setDeletingGameId(null);
    }, [games]);

    return (
        <>
            <form onSubmit={(e) => { e.preventDefault() }} className={styles.searchForm}>
                <input
                    type="text"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                    placeholder="Enter Game Name"
                    className={styles.searchInput}
                />
                <button type="submit" className={styles.searchButton}>Search</button>
            </form>

            {myGames && (
                <button className={styles.createNewGameButton}
                    
                >
                    Create New Game
                </button>
            )}
            {filteredGames.length === 0 ? (
                <div className={styles.ghostContainer}>
                    <GhostIcon className={styles.ghostIcon} />
                    <p>No games found</p>
                </div>
            ) : (
                <div className={styles.gamesList}>
                    {filteredGames.map(game => (
                        <div key={game.gameId} className={styles.gameCard}>
                            <div className={styles.gameCardContent}>
                                <div className={styles.gameInfo}>
                                    <h3>{game.gameName}</h3>
                                    <p className={styles.hostInfo}>Hosted by {game.creatorName}</p>
                                </div>
                                <div className={styles.buttonsContainer}>
                                    {game.creatorName === username && (
                                        deletingGameId === game.gameId ? (
                                            <>
                                                <button 
                                                    className={`${styles.deleteButton} ${styles.confirm}`}
                                                    onClick={() => handleDeleteClick(game.gameId)}
                                                >
                                                    <Check size={16} />
                                                </button>
                                                <button 
                                                    className={`${styles.deleteButton} ${styles.cancel}`}
                                                    onClick={handleCancelDelete}
                                                >
                                                    <X size={16} />
                                                </button>
                                            </>
                                        ) : (
                                            <button 
                                                className={styles.deleteButton}
                                                onClick={() => handleDeleteClick(game.gameId)}
                                            >
                                                <Trash size={16} />
                                            </button>
                                        )
                                    )}
                                    <button 
                                        className={`${styles.joinButton} ${game.phase !== 'lobby' ? styles.disabled : ''}`}
                                        onClick={() => onJoinGame(game.gameId)}
                                        disabled={game.phase !== 'lobby'}
                                    >
                                        <Users size={16} />
                                        {game.playerCount}/8
                                        {game.phase === 'lobby' ? ' Join' : ' Playing'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            
        </>
    )
}

export default OtherGames