import React, { useState } from 'react';
import styles from '../styles/VoteModal.module.css';
import { Vote } from '../types/game';

interface VoteModalProps {
    vote: Vote;
    currentPlayerId: string;
    onSubmitVote: (agree: boolean) => void;
    onSelectCards?: (cardIds: string[]) => void;
    playerHand?: any[];
    playerNames: { [key: string]: string };
    timeLeft?: number;
}

const VoteModal: React.FC<VoteModalProps> = ({
    vote,
    currentPlayerId,
    onSubmitVote,
    onSelectCards,
    playerHand,
    playerNames,
    timeLeft
}) => {
    const [selectedCards, setSelectedCards] = useState<string[]>([]);
    const hasVoted = Object.prototype.hasOwnProperty.call(vote.votes, currentPlayerId);
    const isInitiator = vote.initiator === currentPlayerId;

    const totalPlayers = Object.keys(playerNames).length;
    const votesNeeded = Math.floor(totalPlayers / 2) + 1;
    const currentYesVotes = Object.values(vote.votes).filter(v => v).length;

    const [cardSelected, setCardSelected] = useState(false);




    const handleCardSelect = (cardId: string) => {
        setSelectedCards(prev => {
            if (prev.includes(cardId)) {
                return prev.filter(id => id !== cardId);
            }
            if (prev.length < vote.cardCount) {
                return [...prev, cardId];
            }
            return prev;
        });
    };

   /*  const renderVoteProgress = () => {
        const percentage = (currentYesVotes / totalPlayers) * 100;
        return (
            <div className={styles.progressContainer}>
                <div className={styles.progressBar} style={{ width: `${percentage}%` }} />
                <span className={styles.progressText}>
                    {currentYesVotes} / {votesNeeded} needed ({Object.keys(vote.votes).length} votes cast)
                </span>
            </div>
        );
    }; */



    const renderActiveVote = () => (
        <div className={styles.cuteCard}>
            <div className={styles.cuteHeader}>
                <h3 className={styles.cuteTitle}>Cute Vote Time!</h3>
            </div>

            <div className={styles.cuteContent}>
                <div className={styles.initiatorMessage}>
                    {playerNames[vote.initiator]} wants to change {vote.cardCount} cards!
                </div>

                <div className={styles.voteProgress}>
                    <div className={styles.progressStats}>
                        <span>Votes needed: {votesNeeded}</span>
                        <span>Votes so far: {currentYesVotes}</span>
                    </div>
                    <div className={styles.progressBar}>
                        <div
                            className={styles.progressIndicator}
                            style={{ width: `${(currentYesVotes / totalPlayers) * 100}%` }}
                        />
                    </div>
                </div>

                {timeLeft !== undefined && (
                    <div className={styles.timerContainer}>
                        <span className={styles.timerText}>
                            {timeLeft}s left
                        </span>
                    </div>
                )}
            </div>

            {!hasVoted && !isInitiator && (
                <div className={styles.cuteFooter}>
                    <button
                        className={`${styles.voteButton} ${styles.voteYes}`}
                        onClick={() => onSubmitVote(true)}
                    >
                        Vote Yes!
                    </button>
                    <button
                        className={`${styles.voteButton} ${styles.voteNo}`}
                        onClick={() => onSubmitVote(false)}
                    >
                        Vote No
                    </button>
                </div>
            )}
        </div>
    );

    const renderCardSelection = () => {
        if (cardSelected) {
            const readyCount = Object.keys(vote.cardsToChange || {}).length;
            const totalPlayers = vote.totalSelections || Object.keys(playerNames).length;

            return (
                <div className={styles.selectionCard}>
                    <div className={styles.selectionHeader}>
                        <h3 className={styles.selectionTitle}>
                            Waiting for Players
                        </h3>
                        <p className={styles.selectionSubtitle}>
                            {readyCount === totalPlayers ?
                                "Everyone's ready! Starting soon..." :
                                `${readyCount} out of ${totalPlayers} players are ready`
                            }
                        </p>
                    </div>

                    <div className={styles.selectionContent}>
                        <div className={styles.selectionProgress}>
                            <div className={styles.progressTracker}>
                                <span>Progress</span>
                                <span>{readyCount}/{totalPlayers} ready</span>
                            </div>
                            <div className={styles.progressTrack}>
                                <div
                                    className={styles.progressFill}
                                    style={{ width: `${(readyCount / totalPlayers) * 100}%` }}
                                />
                            </div>
                        </div>

                        <div className={styles.playerGrid}>
                            {Object.entries(playerNames).map(([id, name]) => {
                                const hasSelected = vote.cardsToChange && Object.hasOwn(vote.cardsToChange, id);
                                return (
                                    <div
                                        key={id}
                                        className={`${styles.playerBox} ${hasSelected ? styles.playerReady : ''}`}
                                    >
                                        <div className={styles.playerDetails}>
                                            <div className={styles.playerInitials}>
                                                <span>{name.slice(0, 2).toUpperCase()}</span>
                                            </div>
                                            <span className={styles.playerLabel}>{name}</span>
                                        </div>
                                        <div className={styles.statusBadge}>
                                            {hasSelected ? '✓' : '⏰'}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className={styles.selectionFooter}>
                        <button
                            className={styles.selectionButton}
                            disabled={readyCount !== totalPlayers}
                        >
                            {readyCount === totalPlayers ? (
                                <span>Starting Game ♥</span>
                            ) : (
                                <span>Waiting for others ⏰</span>
                            )}
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.selectionCard}>
                <div className={styles.selectionHeader}>
                    <h3 className={styles.selectionTitle}>Pick Your LEAST Favorite Cards!</h3>
                    <p className={styles.selectionSubtitle}>
                        Choose {vote.cardCount} {vote.cardCount === 1 ? 'card' : 'cards'} to change
                    </p>
                </div>

                <div className={styles.cardContainer}>
                    {playerHand?.map(card => (
                        <div
                            key={card.id}
                            className={`${styles.cardChoice} ${selectedCards.includes(card.id) ? styles.cardSelected : ''}`}
                            onClick={() => handleCardSelect(card.id)}
                        >
                            <p className={styles.cardContent}>{card.text}</p>
                            {selectedCards.includes(card.id) && (
                                <div className={styles.selectionMark}>♥</div>
                            )}
                        </div>
                    ))}
                </div>

                <div className={styles.selectionFooter}>
                    <button
                        className={styles.selectionButton}
                        disabled={selectedCards.length !== vote.cardCount}
                        onClick={() => {
                            setCardSelected(true);
                            onSelectCards?.(selectedCards);
                        }}
                    >
                        {selectedCards.length === vote.cardCount ? (
                            <>Confirm My Cute Picks! ♥</>
                        ) : (
                            `Select ${vote.cardCount - selectedCards.length} more card${vote.cardCount - selectedCards.length !== 1 ? 's' : ''
                            }!`
                        )}
                    </button>
                </div>
            </div>
        );
    };

    const renderVoteResult = () => {
        if (vote.status !== 'passed' && vote.status !== 'failed') return null;

        const yesVotes = Object.values(vote.votes).filter(v => v).length;
        const resultMessage = vote.status === 'passed'
            ? `Vote passed! (${yesVotes}/${totalPlayers} voted yes)`
            : `Vote failed (${yesVotes}/${totalPlayers} voted yes)`;

        return (
            <div className={styles.cuteCard}>
                <div className={styles.cuteHeader}>
                    <h3 className={styles.cuteTitle}>
                        {vote.status === 'passed' ? '✓ Vote Passed!' : '✗ Vote Failed'}
                    </h3>
                </div>
                <div className={styles.cuteContent}>
                    <div className={`${styles.voteResult} ${vote.status === 'passed' ? styles.passed : styles.failed}`}>
                        <p>{resultMessage}</p>
                    </div>
                </div>
            </div>
        );
    };



    return (
        <div className={styles.modalOverlay}>
            {vote.status === 'active' && renderActiveVote()}
            {(vote.status === 'passed' || vote.status === 'failed') && renderVoteResult()}
            {vote.status === 'selecting' && renderCardSelection()}
        </div>
    );
};

export default VoteModal; 