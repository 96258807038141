import React, { useEffect } from 'react';
import styles from '../styles/ModernUI.module.css';
import { Card as GameCard, BlackCard } from '../types/game';
import CardOverlay from './CardOverlay';

export type CardType = GameCard | BlackCard;

interface CardProps {
    card: CardType | CardType[];
    onClick?: () => void;
    isRevealed?: boolean;
    isSelectable?: boolean;
    isSelected?: boolean;
    isPlayed?: boolean;
    packImg?: string;
    gamePhase?: string;
}

const CardLayout: React.FC<CardProps> = ({
    card,
    onClick,
    isRevealed = true,
    isSelectable = false,
    isSelected = false,
    isPlayed = false,
    packImg,
    gamePhase = 'playing'
}) => {
    // Convert cards to array and normalize black cards
    const normalizeCard = (c: CardType): GameCard => {
        if ('type' in c && c.type === 'black') {
            return {
                ...c,
                createdAt: c.createdAt || new Date().toISOString(),
                pack: c.pack || 'base',
                createdBy: c.createdBy || 'system'
            };
        }
        return c as GameCard;
    };

    const cards = Array.isArray(card) ? card.map(normalizeCard) : [normalizeCard(card)];
    const deckImageUrl = packImg || "http://api.hunagainsthumanity.com/uploads/default-pack-cover.de6e68ba7e1bace5e416.webp";
 
    useEffect(() => {
    }, [isPlayed, isSelectable, gamePhase]);

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (isSelectable && onClick) {
            onClick();
        }
    };

    return (
        <div
            onClick={handleClick}
            className={styles.cardContainer}
            style={{
                display: 'flex',
                gap: '10px',
                flexDirection: cards.length > 2 ? 'column' : 'row',
                width: cards.length > 2 ? '180px' : `${180 * Math.min(cards.length, 2)}px`,
                transform: isSelected ? 'translateY(-5px)' : 'none',
                cursor: isSelectable ? 'pointer' : 'default',
                opacity: gamePhase === 'playing' && (isPlayed || (!isSelectable && !isPlayed)) ? 0.5 : 1
            }}
        >
            {cards.map((singleCard, index) => (
                <div
                    key={index}
                    className={`
                        ${styles.card}
                        ${isRevealed ? styles.revealed : ''} 
                        ${isSelectable ? styles.selectable : ''}
                        ${isPlayed ? styles.played : ''}
                    `}
                    style={{
                        position: 'relative',
                        overflow: 'auto',
                        transition: 'all 0.2s ease-in-out',
                        height: cards.length > 2 ? '125px' : '250px',
                        width: '180px',
                        backgroundColor: singleCard.type === 'black' ? 'black' : 'white',
                        color: singleCard.type === 'black' ? 'white' : 'black',
                        border: isSelected ? '2px solid var(--border-color)' : '1px solid var(--card-border)',
                        borderRadius: '5px',
                        flexShrink: 0,
                    }}
                >
                    {/* Card background overlay */}
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${deckImageUrl})`,
                            opacity: isRevealed || isSelected ? 0.1 : 1,
                        }}
                    />

                    {/* Card border overlay */}
                    {!isRevealed ? (
                        <div style={{
                            position: 'absolute',
                            inset: 0,
                            border: '10px solid rgba(255,255,255,0.1)',
                            borderRadius: '2px'
                        }} />
                    ) : (
                        <div style={{
                            position: 'absolute',
                            inset: 0,
                            border: '10px solid rgba(0,0,0,0.1)',
                            borderRadius: '2px'
                        }} />
                    )}

                    {/* New animated overlays */}
                    {isPlayed && gamePhase === 'playing' && (
                        <CardOverlay type="played" />
                    )}
                    
                    {!isSelectable && !isPlayed && isRevealed && gamePhase === 'playing' && (
                        <CardOverlay type="card-czar" />
                    )}

                    {isRevealed && (
                        <div
                            className={styles.cardContent}
                            style={{
                                fontSize: cards.length > 2 ? '0.8em' : undefined
                            }}
                        >
                            {singleCard.text}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default CardLayout;
