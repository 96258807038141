// Lobby.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../services/api';
import styles from '../styles/Lobby.module.css';
import OtherGames from './lobbyComponents/OtherGames';
import { CardPack, GameInfo, GameNotification } from '../types/game';
import { useAuth } from '../contexts/AuthContext';
import { Crown, GamepadIcon, Plus } from 'lucide-react';
import CreateGameModal from './lobbyComponents/CreateGameModal';

interface LobbyProps {
    onlineUsers: string[];
    socket: any;
}

const Lobby: React.FC<LobbyProps> = ({  socket }) => {
    const [username, setUsername] = useState('');
    const [notifications, setNotifications] = useState<GameNotification[]>([]);
    const [allGames, setAllGames] = useState<GameInfo[]>([]);
    const navigate = useNavigate();
    const { username: authUsername, userId } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [availablePacks, setAvailablePacks] = useState<CardPack[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const searchContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (authUsername) {
            setUsername(authUsername);
        }
    }, [authUsername]);

    useEffect(() => {

        const fetchGames = async () => {
            try {
                const games = await api.getCurrentGames();
                console.log("games in Lobby:", games);
                setAllGames(games);
            } catch (error) {
                console.error('Error fetching games:', error);
                setNotifications(prev => [...prev, {
                    id: Date.now(),
                    title: 'Error',
                    message: 'Failed to fetch games. Please try again.'
                }]);
            }
        };
        const fetchPacks = async () => {
            try {
                const packs = await api.getAvailablePacks();
                console.log("availablePacks in MyGames:", packs);
                setAvailablePacks(packs);
            } catch (error) {
                console.error('Error fetching card packs:', error);
            }
        };
        fetchGames();
        fetchPacks();
        const interval = setInterval(fetchGames, 3000); // Refresh every 3 seconds

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        console.log("socket in Lobby:", socket);
    }, [socket]);

    useEffect(() => {

        console.log("wasuserId in Lobby:", userId);
        console.log("wasusername in Lobby:", username);
        console.log("wasdauthUsername in Lobby:", authUsername);
    }, [userId, username, authUsername]);


    // Unnecesarry right now
    console.log("notifications in lobby:", notifications);

    

    const handleJoinGame = async (gameId: string) => {
        if (!username) {
            setNotifications(prev => [...prev, {
                id: Date.now(),
                title: 'Error',
                message: 'Please set a username before joining a game.'
            }]);
            return;
        }

        try {
            const existingPlayerId = userId || localStorage.getItem(`playerId_${gameId}`);
            let playerId;

            if (existingPlayerId) {
                console.log(`Player already in game. Using existing playerId: ${existingPlayerId}`);
                playerId = existingPlayerId;
            } else {
                console.log(`Joining game ${gameId} with username ${username}`);
                const response = await api.joinGame(gameId, username);
                playerId = response.playerId;
                localStorage.setItem(`playerId_${gameId}`, playerId);
            }

            localStorage.setItem(`playerName_${gameId}`, username);
            navigate(`/${gameId}`, { state: { username, playerId } });
        } catch (error) {
            console.error('Error joining game:', error);
            setNotifications(prev => [...prev, {
                id: Date.now(),
                title: 'Error',
                message: 'Failed to join game. Please try again.'
            }]);
        }
    };

    const handleDeleteGame = async (gameId: string) => {
        console.log("deleting game:", gameId);
        console.log("username in handleDeleteGame:", username);
        if (!username) {
            setNotifications(prev => [...prev, {
                id: Date.now(),
                title: 'Error',
                message: 'Please set a username before deleting a game.'
            }]);
            return;
        }

        try {
            const creatorId = localStorage.getItem(`creatorId_${gameId}`);
            if (!creatorId) {
                setNotifications(prev => [...prev, {
                    id: Date.now(),
                    title: 'Error',
                    message: 'You are not the creator of this game.'
                }]);
                return;
            }

            await api.deleteGame(gameId, creatorId);
            setAllGames(prevGames => prevGames.filter(game => game.gameId !== gameId));
            setNotifications(prev => [...prev, {
                id: Date.now(),
                title: 'Success',
                message: 'Game successfully deleted.'
            }]);
        } catch (error) {
            console.error('Error deleting game:', error);
            setNotifications(prev => [...prev, {
                id: Date.now(),
                title: 'Error',
                message: 'Failed to delete game. Please try again.'
            }]);
        }
    };

    const handleCreateGame = async (gameSettings: {
        gameName: string,
        winningScore: number,
        selectedBlackPacks: CardPack[],
        selectedWhitePacks: CardPack[],
        botCount: number
    }) => {
        try {
            console.log("Creating game with packs:", {
                black: gameSettings.selectedBlackPacks,
                white: gameSettings.selectedWhitePacks
            });

            const { gameId, creatorId, playerId } = await api.createGame(
                username,
                gameSettings.gameName,
                gameSettings.winningScore,
                gameSettings.selectedBlackPacks,
                gameSettings.selectedWhitePacks,
                gameSettings.botCount
            );

            localStorage.setItem(`playerId_${gameId}`, playerId);
            localStorage.setItem(`creatorId_${gameId}`, creatorId);

            console.log(`Local storage set for game ${gameId}`);
            handleJoinGame(gameId);
        } catch (error) {
            console.error('Error creating game:', error);
        } finally {
            setIsModalOpen(false);
        }
    };

    const sortedGames = [...allGames].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB.getTime() - dateA.getTime();
    });

    const myGames = sortedGames.filter(game => game.players.includes(username));
    const otherGames = sortedGames.filter(game => !game.players.includes(username));

    const filteredGames = sortedGames.filter(game =>
        game.gameName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.container}>
            <main className={styles.main}>
                <div className={styles.createGameCard}>
                    <div className={styles.createGameContent}>
                        <div className={styles.searchContainer} ref={searchContainerRef}>
                            <input
                                type="text"
                                placeholder="Enter game name..."
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setShowDropdown(true);
                                }}
                                onFocus={() => setShowDropdown(true)}
                                className={styles.gameNameInput}
                            />
                            {showDropdown && searchTerm && (
                                <div className={styles.dropdown}>
                                    {filteredGames.map(game => (
                                        <div
                                            key={game.gameId}
                                            className={styles.dropdownItem}
                                            onClick={() => {
                                                handleJoinGame(game.gameId);
                                                navigate(`/${game.gameId}`);
                                                setShowDropdown(false);
                                            }}
                                        >
                                            {game.gameName} ({game.playerCount}/8)
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <button 
                            className={styles.createButton}
                            onClick={() => setIsModalOpen(true)}
                        >
                            <Plus size={20} />
                            <span> Create New Game</span>
                           
                        </button>
                    </div>
                </div>

                <div className={styles.gamesGrid}>
                    <div className={styles.gameSection}>
                        <div className={styles.sectionHeader}>
                            <Crown className={styles.sectionIcon} size={24} />
                            <h2>Your Games</h2>
                        </div>
                        <div className={styles.gamesScrollArea}>
                            <OtherGames
                                games={myGames}
                                onJoinGame={handleJoinGame}
                                onDeleteGame={handleDeleteGame}
                                username={username}
                                myGames={true}
                            />
                        </div>
                    </div>

                    <div className={styles.gameSection}>
                        <div className={styles.sectionHeader}>
                            <GamepadIcon className={styles.sectionIcon} size={24} />
                            <h2>Available Games</h2>
                        </div>
                        <div className={styles.gamesScrollArea}>
                            <OtherGames
                                games={otherGames}
                                onJoinGame={handleJoinGame}
                                onDeleteGame={handleDeleteGame}
                                username={username}
                                myGames={false}
                            />
                        </div>
                    </div>
                </div>
                <CreateGameModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onCreateGame={handleCreateGame}
                availablePacks={availablePacks}
            />
            </main>

        </div>
    );
};

export default Lobby;
