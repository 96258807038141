import React, { useState, useEffect, useRef, useCallback } from 'react';
import { api } from '../services/api';
import { Plus, X, Upload } from 'lucide-react';
import { CardPack, Card } from '../types/game';
import styles from '../styles/DeckManagement.module.css';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import defaultDeckCover from '../resources/images/default-pack-cover.webp';
import debounce from 'lodash/debounce';

interface JSONDeck {
    name: string;
    image?: string;
    cards: {
        text: string;
        type: 'black' | 'white';
        blanks?: number;
    }[];
}

interface DeckCardProps {
    pack: CardPack;
    onClick: () => void;
}

interface UploadError {
    card: {
        text: string;
        type: 'black' | 'white';
        blanks?: number;
    };
    error: string;
}

interface AddNewCardModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (card: { text: string; type: 'black' | 'white'; blanks?: number }) => void;
}

const DeckCard: React.FC<DeckCardProps> = ({ pack, onClick }) => {
    return (
        <div className={`${styles.deckCard} ${styles.slideInUp}`} onClick={onClick}>
            <div className={styles.deckImageContainer}>
                <img
                    src={pack.imageUrl ? pack.imageUrl : defaultDeckCover}
                    alt={pack.name}
                    className={styles.deckImage}
                />
            </div>
            <div className={styles.deckInfo}>
                <h3 className={styles.deckName}>{pack.name}</h3>
                <div className={styles.deckStats}>
                    <span className={styles.statBadge}>{pack.whiteCardCount} white</span>
                    <span className={styles.statBadge}>{pack.blackCardCount} black</span>
                </div>
            </div>
        </div>
    );
};

const EditCardModal: React.FC<{
    card: Card;
    onSave: (updatedCard: Card) => void;
    onDelete: (cardId: string, userId: string) => void;
    onClose: () => void;
}> = ({ card, onSave, onDelete, onClose }) => {
    const [text, setText] = useState(card.text);
    const [type, setType] = useState(card.type);
    const [blanks, setBlanks] = useState(card.blanks || 1);
    console.log("1234CARD", card);
    const handleSave = () => {
        onSave({ ...card, text, type, blanks: type === 'black' ? blanks : undefined });
    };

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this card?')) {
            onDelete(card.id, card.createdBy);
        }
    };

    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <h2>Edit Card</h2>
                <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className={styles.textarea}
                />
                <select
                    value={type}
                    onChange={(e) => setType(e.target.value as 'black' | 'white')}
                    className={styles.select}
                >
                    <option value="white">White Card</option>
                    <option value="black">Black Card</option>
                </select>
                {type === 'black' && (
                    <input
                        type="number"
                        value={blanks}
                        onChange={(e) => setBlanks(Number(e.target.value))}
                        min={1}
                        max={3}
                        className={styles.input}
                    />
                )}
                <div className={styles.modalButtons}>
                    <button onClick={handleSave} className={styles.button}>Save</button>
                    <button onClick={handleDelete} className={`${styles.button} ${styles.deleteButton}`}>Delete</button>
                    <button onClick={onClose} className={styles.button}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

const CreateDeckModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    onCreatePack: () => Promise<void>;
    newPackName: string;
    setNewPackName: (name: string) => void;
    newPackVisibility: 'public' | 'private';
    setNewPackVisibility: (visibility: 'public' | 'private') => void;
    imageInputRef: React.RefObject<HTMLInputElement>;
    handleImageSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
    imagePreview: string;
    setImagePreview: (preview: string) => void;
    setUploadedImage: (image: File | null) => void;
}> = ({ isOpen, onClose, onCreatePack, newPackName, setNewPackName, newPackVisibility, setNewPackVisibility, imageInputRef, handleImageSelect, imagePreview, setImagePreview, setUploadedImage }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <h2 className={styles.modalTitle}>Create New Deck</h2>
                <div className={styles.formGroup}>
                    <label htmlFor="deckName">Deck Name</label>
                    <input
                        id="deckName"
                        value={newPackName}
                        onChange={(e) => setNewPackName(e.target.value)}
                        className={styles.input}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label>Visibility</label>
                    <div className={styles.radioGroup}>
                        <label>
                            <input
                                type="radio"
                                value="public"
                                checked={newPackVisibility === 'public'}
                                onChange={(e) => setNewPackVisibility(e.target.value as 'public' | 'private')}
                            />
                            Public
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="private"
                                checked={newPackVisibility === 'private'}
                                onChange={(e) => setNewPackVisibility(e.target.value as 'public' | 'private')}
                            />
                            Private
                        </label>
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <label>Deck Image</label>
                    <div className={styles.imageUploadSection}>
                        <button
                            onClick={() => imageInputRef.current?.click()}
                            className={styles.uploadButton}
                        >
                            <Upload className={styles.icon} />
                            Upload Image
                        </button>
                        <input
                            type="file"
                            ref={imageInputRef}
                            onChange={handleImageSelect}
                            accept="image/*"
                            className={styles.hiddenInput}
                        />
                        {imagePreview && (
                            <div className={styles.imagePreview}>
                                <img src={imagePreview} alt="Deck preview" />
                                <button
                                    onClick={() => {
                                        setImagePreview("");
                                        setUploadedImage(null);
                                        if (imageInputRef.current) imageInputRef.current.value = "";
                                    }}
                                    className={styles.removeImageButton}
                                >
                                    <X />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.modalButtons}>
                    <button onClick={onCreatePack} className={styles.createButton}>
                        Create Deck
                    </button>
                    <button onClick={onClose} className={styles.cancelButton}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

const AddNewCardModal: React.FC<AddNewCardModalProps> = ({ isOpen, onClose, onSave }) => {
    const [text, setText] = useState('');
    const [type, setType] = useState<'black' | 'white'>('white');
    const [blanks, setBlanks] = useState(1);

    const handleSave = () => {
        onSave({
            text,
            type,
            blanks: type === 'black' ? blanks : undefined
        });
        // Reset form
        setText('');
        setType('white');
        setBlanks(1);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <h2>Add New Card</h2>
                <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Enter card text..."
                    className={styles.textarea}
                />
                <select
                    value={type}
                    onChange={(e) => setType(e.target.value as 'black' | 'white')}
                    className={styles.select}
                >
                    <option value="white">White Card</option>
                    <option value="black">Black Card</option>
                </select>
                {type === 'black' && (
                    <div className={styles.formGroup}>
                        <label>Number of Blanks</label>
                        <input
                            type="number"
                            value={blanks}
                            onChange={(e) => setBlanks(Number(e.target.value))}
                            min={1}
                            max={3}
                            className={styles.input}
                        />
                    </div>
                )}
                <div className={styles.modalButtons}>
                    <button 
                        onClick={handleSave} 
                        className={styles.button}
                        disabled={!text.trim()}
                    >
                        Save
                    </button>
                    <button onClick={onClose} className={styles.button}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

const DeckManagement: React.FC = () => {
    const [packs, setPacks] = useState<CardPack[]>([]);
    const [newPackName, setNewPackName] = useState('');
    const [newPackVisibility, setNewPackVisibility] = useState<'public' | 'private'>('public');
    const [selectedPack, setSelectedPack] = useState('');
    // const [newCardText, setNewCardText] = useState('');
    // const [newCardType, setNewCardType] = useState<'black' | 'white'>('white');
    // const [newCardBlanks, setNewCardBlanks] = useState(1);
    const [selectedPackCards, setSelectedPackCards] = useState<Card[]>([]);
    const [cardTypeFilter, setCardTypeFilter] = useState<'all' | 'white' | 'black'>('all');
    const [sortOrder, setSortOrder] = useState<'newest' | 'oldest'>('newest');
    const [editingCard, setEditingCard] = useState<Card | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState<'original' | 'custom'>('original');
    const [jsonError, setJsonError] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [uploadedImage, setUploadedImage] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string>('');
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [uploadErrors, setUploadErrors] = useState<UploadError[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isExistingDeckUploading, setIsExistingDeckUploading] = useState(false);
    const existingDeckFileInputRef = useRef<HTMLInputElement>(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isAddNewCardModalOpen, setIsAddNewCardModalOpen] = useState(false);
    const [searchSuggestions, setSearchSuggestions] = useState<string[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        loadPacks();
    }, [activeTab]);

    useEffect(() => {
        if (selectedPack) {
            loadPackCards(selectedPack);
        }
    }, [selectedPack]);

    useEffect(() => {
        // Initialize state from URL parameters
        const params = new URLSearchParams(window.location.search);
        const tabParam = params.get('tab') as 'original' | 'custom';
        const deckParam = params.get('deck');
        const searchParam = params.get('search');
        const filterParam = params.get('filter') as 'all' | 'white' | 'black';
        const sortParam = params.get('sort') as 'newest' | 'oldest';

        if (tabParam) setActiveTab(tabParam);
        if (deckParam) setSelectedPack(deckParam);
        if (searchParam) setSearchTerm(searchParam);
        if (filterParam) setCardTypeFilter(filterParam);
        if (sortParam) setSortOrder(sortParam);
    }, []); // Run only once on mount

    const loadPacks = async () => {
        const fetchedPacks = await api.getAvailablePacks();
        setPacks(fetchedPacks);
    };

    const loadPackCards = async (packId: string) => {
        const cards = await api.getPackCards(packId);
        console.log("CARDS", cards);
        setSelectedPackCards(cards);
    };

    const handleCreatePack = async () => {
        console.log('Starting pack creation...');
        let imageUrl = '';
        if (uploadedImage) {
            console.log('Uploading image for pack...');
            imageUrl = await handleImageUpload() || '';
            console.log('Received image URL:', imageUrl);
        }

        try {
            console.log('Creating pack with:', {
                name: newPackName,
                visibility: newPackVisibility,
                imageUrl
            });
            await api.createPack(newPackName, newPackVisibility === 'public', imageUrl);
            console.log('Pack created successfully');

            // Reset form
            setNewPackName('');
            setNewPackVisibility('public');
            setUploadedImage(null);
            setImagePreview('');
            if (imageInputRef.current) {
                imageInputRef.current.value = '';
            }
            loadPacks();
        } catch (error) {
            console.error('Error creating pack:', error);
        }
    };

    /* const handleCreateCard = async () => {
        if (selectedPack) {
            await api.createCard(selectedPack, newCardText, newCardType, newCardType === 'black' ? newCardBlanks : undefined);
            setNewCardText('');
            setNewCardType('white');
            setNewCardBlanks(1);
            loadPackCards(selectedPack);
        }
    };

    const handlePackChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPack(e.target.value);
    }; */

    const handleUpdateCard = async (updatedCard: Card) => {
        try {
            if (!updatedCard.id) {
                console.error('Card ID is undefined');
                return;
            }
            await api.editCard(updatedCard.id, updatedCard.text, updatedCard.type, updatedCard.blanks);
            setEditingCard(null);
            loadPackCards(selectedPack);
        } catch (error) {
            console.error('Error updating card:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    const handleDeleteCard = async (cardId: string, userId: string) => {
        try {
            if (!cardId) {
                console.error('Card ID is undefined');
                return;
            }
            await api.deleteCard(cardId, userId);
            setEditingCard(null);
            loadPackCards(selectedPack);
        } catch (error) {
            console.error('Error deleting card:', error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    // const handleDeletePack = async (packId: string) => {
    //     if (window.confirm('Are you sure you want to delete this pack? This action cannot be undone.')) {
    //         try {
    //             await api.deletePack(packId);
    //             loadPacks();
    //             setSelectedPack('');
    //         } catch (error) {
    //             console.error('Error deleting pack:', error);
    //         }
    //     }
    // };

    const filteredAndSortedCards = selectedPackCards
        .filter(card =>
            (cardTypeFilter === 'all' || card.type === cardTypeFilter) &&
            card.text.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            if (sortOrder === 'newest') {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            } else {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            }
        });

    const handleJSONUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        setIsUploading(true);
        setUploadProgress(0);
        setUploadErrors([]);
        const errors: UploadError[] = [];

        try {
            const text = await file.text();
            const deck: JSONDeck = JSON.parse(text);

            // Validate deck structure
            if (!deck.name || !Array.isArray(deck.cards)) {
                throw new Error('Invalid deck format: must include name and cards array');
            }

            // Create the deck
            const newPack = await api.createPack(deck.name, true);

            // Add cards one by one and track progress
            const totalCards = deck.cards.length;
            for (let i = 0; i < deck.cards.length; i++) {
                const card = deck.cards[i];
                try {
                    // Validate card
                    if (!card.text || !card.type) {
                        throw new Error('Card must include text and type');
                    }
                    if (card.type === 'black' && typeof card.blanks !== 'number') {
                        throw new Error('Black card must specify number of blanks');
                    }

                    await api.createCard(
                        newPack.id,
                        card.text,
                        card.type,
                        card.type === 'black' ? card.blanks : undefined
                    );

                    // Update progress
                    setUploadProgress(((i + 1) / totalCards) * 100);
                } catch (error) {
                    errors.push({
                        card,
                        error: error instanceof Error ? error.message : 'Unknown error'
                    });
                }
            }

            loadPacks();
            if (errors.length > 0) {
                setUploadErrors(errors);
            }
            setJsonError(null);
        } catch (error) {
            setJsonError(error instanceof Error ? error.message : 'Invalid JSON format');
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            console.log('Image selected:', {
                name: file.name,
                size: file.size,
                type: file.type
            });
            setUploadedImage(file);
            // Create preview URL
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
        } else {
            console.log('No file selected');
        }
    };

    const handleImageUpload = async () => {
        if (!uploadedImage) {
            console.log('No image selected for upload');
            return;
        }

        console.log('Preparing image upload:', {
            fileName: uploadedImage.name,
            fileSize: uploadedImage.size,
            fileType: uploadedImage.type
        });

        const formData = new FormData();
        formData.append('image', uploadedImage);

        try {
            console.log('Sending image upload request...');
            const response = await api.uploadDeckImage(formData);
            console.log('Image upload successful:', response);
            return response.imageUrl;
        } catch (error) {
            console.error('Error uploading image:', error);
            console.error('Full error object:', JSON.stringify(error, null, 2));
            return null;
        }
    };

    const handleExistingDeckJSONUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file || !selectedPack) return;

        setIsExistingDeckUploading(true);
        setUploadErrors([]);
        const errors: UploadError[] = [];

        try {
            const text = await file.text();
            const deck: JSONDeck = JSON.parse(text);

            // Validate deck structure
            if (!Array.isArray(deck.cards)) {
                throw new Error('Invalid deck format: must include cards array');
            }

            // Add cards one by one and collect errors
            for (const card of deck.cards) {
                try {
                    if (!card.text || !card.type) {
                        throw new Error('Card must include text and type');
                    }
                    if (card.type === 'black' && typeof card.blanks !== 'number') {
                        throw new Error('Black card must specify number of blanks');
                    }

                    await api.createCard(
                        selectedPack,
                        card.text,
                        card.type,
                        card.type === 'black' ? card.blanks : undefined
                    );
                } catch (error) {
                    errors.push({
                        card,
                        error: error instanceof Error ? error.message : 'Unknown error'
                    });
                }
            }

            if (errors.length > 0) {
                setUploadErrors(errors);
            }
            loadPackCards(selectedPack);
            setJsonError(null);
        } catch (error) {
            setJsonError(error instanceof Error ? error.message : 'Invalid JSON format');
        } finally {
            setIsExistingDeckUploading(false);
            if (existingDeckFileInputRef.current) {
                existingDeckFileInputRef.current.value = '';
            }
        }
    };

    const handleAddNewCard = async (cardData: { text: string; type: 'black' | 'white'; blanks?: number }) => {
        try {
            await api.createCard(
                selectedPack,
                cardData.text,
                cardData.type,
                cardData.blanks
            );
            loadPackCards(selectedPack);
        } catch (error) {
            console.error('Error creating card:', error);
            // You might want to show an error message to the user here
        }
    };

    // Filter packs based on active tab
    const displayedPacks = packs.filter(pack =>
        activeTab === 'original' ? pack.isOriginal : !pack.isOriginal
    );

    // Update URL parameters
    const updateURLParams = useCallback(
        debounce((params: {
            tab?: string;
            deck?: string;
            search?: string;
            filter?: string;
            sort?: string;
        }) => {
            const newSearchParams = new URLSearchParams(searchParams);
            
            Object.entries(params).forEach(([key, value]) => {
                if (value) {
                    newSearchParams.set(key, value);
                } else {
                    newSearchParams.delete(key);
                }
            });

            setSearchParams(newSearchParams);
        }, 300),
        [setSearchParams]
    );

    // Update handlers
    const handleTabChange = (tab: 'original' | 'custom') => {
        setActiveTab(tab);
        updateURLParams({ tab });
    };

    const handleDeckSelect = (deckId: string) => {
        setSelectedPack(deckId);
        updateURLParams({ deck: deckId });
    };

    const handleSearchChange = (value: string) => {
        setSearchTerm(value);
        updateURLParams({ search: value });
        
        if (value.trim()) {
            const suggestions = selectedPackCards
                .map(card => card.text)
                .filter(text => 
                    text.toLowerCase().includes(value.toLowerCase()) &&
                    text.toLowerCase() !== value.toLowerCase()
                )
                .slice(0, 5);
            setSearchSuggestions(suggestions);
            setShowSuggestions(true);
        } else {
            setSearchSuggestions([]);
            setShowSuggestions(false);
        }
    };

    const handleFilterChange = (filter: 'all' | 'white' | 'black') => {
        setCardTypeFilter(filter);
        updateURLParams({ filter });
    };

    const handleSortChange = (sort: 'newest' | 'oldest') => {
        setSortOrder(sort);
        updateURLParams({ sort });
    };

    // Handle browser back/forward
    useEffect(() => {
        const handlePopState = () => {
            const params = new URLSearchParams(window.location.search);
            setActiveTab((params.get('tab') as 'original' | 'custom') || 'original');
            setSelectedPack(params.get('deck') || '');
            setSearchTerm(params.get('search') || '');
            setCardTypeFilter((params.get('filter') as 'all' | 'white' | 'black') || 'all');
            setSortOrder((params.get('sort') as 'newest' | 'oldest') || 'newest');
        };

        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, []);

    const renderSearchAndFilters = () => (
        <div className={styles.controlsContainer}>
            <div className={styles.searchContainer}>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => handleSearchChange(e.target.value)}
                    placeholder="Search cards..."
                    className={styles.searchInput}
                />
                {showSuggestions && searchSuggestions.length > 0 && (
                    <div className={styles.suggestionsDropdown}>
                        {searchSuggestions.map((suggestion, index) => (
                            <div
                                key={index}
                                className={styles.suggestionItem}
                                onClick={() => {
                                    setSearchTerm(suggestion);
                                    setShowSuggestions(false);
                                }}
                            >
                                {suggestion}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={styles.filterControls}>
                <select
                    value={cardTypeFilter}
                    onChange={(e) => handleFilterChange(e.target.value as 'all' | 'white' | 'black')}
                    className={styles.filterSelect}
                >
                    <option value="all">All Cards</option>
                    <option value="white">White Cards</option>
                    <option value="black">Black Cards</option>
                </select>
                <select
                    value={sortOrder}
                    onChange={(e) => handleSortChange(e.target.value as 'newest' | 'oldest')}
                    className={styles.filterSelect}
                >
                    <option value="newest">Newest First</option>
                    <option value="oldest">Oldest First</option>
                </select>
            </div>
        </div>
    );

    const handleCloseDeck = () => {
        setSelectedPack('');
        // Clear deck-related parameters from URL
        updateURLParams({
            deck: '',      // This will remove the deck parameter
            search: '',    // Clear search
            filter: '',    // Clear filter
            sort: ''      // Clear sort
        });
        // Reset related states
        setSearchTerm('');
        setCardTypeFilter('all');
        setSortOrder('newest');
        setSearchSuggestions([]);
        setShowSuggestions(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.tabList}>
                <button
                    className={`${styles.tabButton} ${activeTab === 'original' ? styles.active : ''}`}
                    onClick={() => handleTabChange('original')}
                >
                    Original Decks
                </button>
                <button
                    className={`${styles.tabButton} ${activeTab === 'custom' ? styles.active : ''}`}
                    onClick={() => handleTabChange('custom')}
                >
                    Custom Decks
                </button>
            </div>

            {activeTab === 'custom' && (
                <div className={styles.createDeckSection}>
                    <button
                        className={styles.createDeckButton}
                        onClick={() => setIsCreateModalOpen(true)}
                    >
                        <Plus size={24} />
                        Create New Deck
                    </button>
                    <CreateDeckModal
                        isOpen={isCreateModalOpen}
                        onClose={() => setIsCreateModalOpen(false)}
                        onCreatePack={handleCreatePack}
                        newPackName={newPackName}
                        setNewPackName={setNewPackName}
                        newPackVisibility={newPackVisibility}
                        setNewPackVisibility={setNewPackVisibility}
                        imageInputRef={imageInputRef}
                        handleImageSelect={handleImageSelect}
                        imagePreview={imagePreview}
                        setImagePreview={setImagePreview}
                        setUploadedImage={setUploadedImage}
                    />

                    <div className={styles.jsonUploadSection}>
                        <input
                            type="file"
                            accept=".json"
                            onChange={handleJSONUpload}
                            ref={fileInputRef}
                            disabled={isUploading}
                        />
                        {isUploading && (
                            <div className={styles.progressContainer}>
                                <div
                                    className={styles.progressBar}
                                    style={{ width: `${uploadProgress}%` }}
                                />
                                <span className={styles.progressText}>
                                    {Math.round(uploadProgress)}%
                                </span>
                            </div>
                        )}
                        {jsonError && <p className={styles.error}>{jsonError}</p>}

                        {uploadErrors.length > 0 && (
                            <div className={styles.errorList}>
                                <h3>Failed to upload the following cards:</h3>
                                {uploadErrors.map((error, index) => (
                                    <div key={index} className={styles.errorItem}>
                                        <p>Card: {error.card.text}</p>
                                        <p>Error: {error.error}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className={styles.decksGrid}>
                {displayedPacks.map(pack => (
                    <DeckCard
                        key={pack.id}
                        pack={pack}
                        onClick={() => handleDeckSelect(pack.id)}
                    />
                ))}
            </div>

            {/* Show cards grid when a deck is selected */}
            {selectedPack && (
                <div className={styles.selectedDeckView}>
                    <div className={styles.selectedDeckHeader}>
                        <h2>{packs.find(p => p.id === selectedPack)?.name}</h2>
                        {activeTab === 'custom' && (
                            <div className={styles.headerActions}>
                                <button 
                                    className={styles.addNewCardButton}
                                    onClick={() => setIsAddNewCardModalOpen(true)}
                                >
                                    Add New Card
                                </button>
                                <input
                                    type="file"
                                    accept=".json"
                                    onChange={handleExistingDeckJSONUpload}
                                    ref={existingDeckFileInputRef}
                                    style={{ display: 'none' }}
                                    disabled={isExistingDeckUploading}
                                />
                                <button
                                    className={styles.uploadButton}
                                    onClick={() => existingDeckFileInputRef.current?.click()}
                                >
                                    <Upload className={styles.icon} />
                                    Upload Cards
                                </button>
                                <button
                                    className={styles.closeButton}
                                    onClick={handleCloseDeck}
                                >
                                    Close
                                </button>
                            </div>
                        )}
                        {activeTab === 'original' && (
                            <button
                                className={styles.closeButton}
                                onClick={handleCloseDeck}
                            >
                                Close
                            </button>
                        )}
                    </div>

                    {/* Add the search and filters component here */}
                    {renderSearchAndFilters()}

                    {isExistingDeckUploading && <p>Uploading cards...</p>}
                    {jsonError && <p className={styles.error}>{jsonError}</p>}
                    {uploadErrors.length > 0 && (
                        <div className={styles.errorList}>
                            <h3>Failed to upload the following cards:</h3>
                            {uploadErrors.map((error, index) => (
                                <div key={index} className={styles.errorItem}>
                                    <p>Card: {error.card.text}</p>
                                    <p>Error: {error.error}</p>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className={styles.cardGrid}>
                        {filteredAndSortedCards.map(card => (
                            <div
                                key={card.id}
                                className={`${styles.gameCard} ${card.type === 'white' ? styles.white : styles.black}`}
                                onClick={() => activeTab === 'custom' && setEditingCard(card)}
                            >
                                <p>{card.text}</p>
                                {card.type === 'black' && <p>Blanks: {card.blanks}</p>}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {editingCard && activeTab === 'custom' && (
                <EditCardModal
                    card={editingCard}
                    onSave={handleUpdateCard}
                    onDelete={handleDeleteCard}
                    onClose={() => setEditingCard(null)}
                />
            )}

            {isAddNewCardModalOpen && (
                <AddNewCardModal
                    isOpen={isAddNewCardModalOpen}
                    onClose={() => setIsAddNewCardModalOpen(false)}
                    onSave={handleAddNewCard}
                />
            )}

            <footer className={styles.footer}>
                <Link to="/" className={styles.footerLink}>
                    Back to Lobby
                </Link>
            </footer>
        </div>
    );
};

export default DeckManagement;
