import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ChatMessage as ChatMessageType } from '../../types/chat';
import styles from '../../styles/chat.module.css';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import socket from '../../services/socket';
import { useAuth } from '../../contexts/AuthContext';

interface ChatBoxProps {
    gameId?: string;
}

/* Debug logging utility */
const logChat = {
    init: (...args: any[]) => console.log('🚀 Chat Init:', ...args),
    message: (...args: any[]) => console.log('💬 Chat Message:', ...args),
    socket: (...args: any[]) => console.log('🔌 chatSocket Event:', ...args),
    error: (...args: any[]) => console.error('❌ Chat Error:', ...args),
    state: (...args: any[]) => console.log('📊 chatState Update:', ...args),
    debug: (...args: any[]) => console.log('🐛 chatDebug:', ...args)
};

export default function ChatBox({ gameId }: ChatBoxProps) {
    const [isActive, setIsActive] = useState(false);
    const [messages, setMessages] = useState<ChatMessageType[]>(() => {
        // Try to load messages from localStorage on initial render
        if (gameId) {
            const saved = localStorage.getItem(`chat_messages_${gameId}`);
            return saved ? JSON.parse(saved) : [];
        }
        return [];
    });
    const [inputMessage, setInputMessage] = useState('');
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const chatBoxRef = useRef<HTMLDivElement>(null);
    const { username } = useAuth();

    // Handle click outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (chatBoxRef.current &&
                !chatBoxRef.current.contains(event.target as Node) &&
                isActive) {
                logChat.debug('Click outside chat box');
                setIsActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isActive]);

    // Initialize socket connection and load saved messages
    useEffect(() => {
        logChat.init('ChatBox mounted', { gameId, username });

        // Ensure socket is connected
        if (!socket.connected) {
            socket.connect();
            logChat.socket('Forcing socket connection');
        }

        // Request recent messages when component mounts
        if (gameId) {
            socket.emit('request_recent_messages', { gameId });
            logChat.socket('Requesting recent messages for game:', gameId);
        }

        return () => {
            logChat.debug('ChatBox unmounting');
        };
    }, [gameId, username]);

    // Save messages to localStorage when they update
    useEffect(() => {
        if (messages.length > 0) {
            logChat.debug('Saving messages to localStorage:', messages.length);
            localStorage.setItem(`chat_messages_${gameId}`, JSON.stringify(messages.slice(-50))); // Keep last 50 messages
        }
    }, [messages, gameId]);

    const scrollToBottom = useCallback(() => {
        if (messagesContainerRef.current && isScrolledToBottom) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
            logChat.debug('Scrolled to bottom');
        }
    }, [isScrolledToBottom]);

    const handleScroll = useCallback(() => {
        if (messagesContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
            const isBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
            setIsScrolledToBottom(isBottom);
            logChat.debug('Scroll position:', { isBottom, scrollTop, scrollHeight, clientHeight });
        }
    }, []);

    useEffect(() => {
        const container = messagesContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    useEffect(() => {
        scrollToBottom();
    }, [messages, scrollToBottom]);

    // Socket event handlers
    useEffect(() => {
        logChat.socket('Setting up socket listeners', { gameId });

        const handleChatMessage = (message: any) => {
            logChat.message('Received message:', message);
            setMessages(prev => {
                // Check for duplicates using the _id field
                const isDuplicate = prev.some(m => m.id === message._id);

                if (isDuplicate) {
                    logChat.debug('Duplicate message detected:', message);
                    return prev;
                }

                const newMessage: ChatMessageType = {
                    id: message._id,
                    content: message.content,
                    timestamp: new Date(message.timestamp),
                    sender: message.isSystemMessage ? 'system' : 'user',
                    username: message.sender,
                    gameId: message.gameId,
                    isSystemMessage: message.isSystemMessage
                };

                const newMessages = [...prev, newMessage];
                // Save to localStorage
                if (gameId) {
                    localStorage.setItem(`chat_messages_${gameId}`, JSON.stringify(newMessages));
                }
                return newMessages;
            });
        };

        const handleRecentMessages = (messages: any[]) => {
            logChat.socket('Received recent messages:', messages.length);
            const transformedMessages = messages.map(message => ({
                id: message._id,
                content: message.content,
                timestamp: new Date(message.timestamp),
                sender: message.isSystemMessage ? 'system' : 'user',
                username: message.sender,
                gameId: message.gameId,
                isSystemMessage: message.isSystemMessage
            }));

            setMessages(transformedMessages as ChatMessageType[]);

            // Save to localStorage
            if (gameId) {
                localStorage.setItem(`chat_messages_${gameId}`, JSON.stringify(transformedMessages));
            }

            logChat.debug('Loaded recent messages:', transformedMessages.length);
        };

        // Clean up existing listeners before adding new ones
        socket.off('chat message');
        socket.off('recent_messages');

        // Add new listeners
        socket.on('chat message', handleChatMessage);
        socket.on('recent_messages', handleRecentMessages);

        // Cleanup function
        return () => {
            logChat.socket('Cleaning up socket listeners');
            socket.off('chat message', handleChatMessage);
            socket.off('recent_messages', handleRecentMessages);
        };
    }, [gameId]); // Only re-run if gameId changes

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!inputMessage.trim() || !username) {
            logChat.debug('Invalid message submission:', { inputMessage, username });
            return;
        }

        const messageData = {
            _id: Date.now().toString(),
            content: inputMessage.trim(),
            sender: username,
            timestamp: new Date(),
            gameId: gameId,
            isSystemMessage: false
        };

        logChat.message('Sending message:', messageData);
        socket.emit('chat message', messageData);
        setInputMessage('');
    };

    return (
        <div
            ref={chatBoxRef}
            className={`${styles.chatBox} ${isActive ? styles.active : ''}`}
        >
            <div
                className={styles.messagesContainer}
                ref={messagesContainerRef}
                onScroll={handleScroll}
            >
                {messages.map((message) => (
                    <ChatMessage
                        key={message.id}
                        message={message}
                    />
                ))}
            </div>

            <div
                className={styles.inputContainer}
                onClick={() => {
                    setIsActive(true);
                    logChat.debug('Chat activated via input container click');
                }}
            >
                <ChatInput
                    inputMessage={inputMessage}
                    setInputMessage={setInputMessage}
                    onSubmit={handleSubmit}
                    onFocus={() => {
                        setIsActive(true);
                        logChat.debug('Chat activated via input focus');
                    }}
                />
            </div>
        </div>
    );
}